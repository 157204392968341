import widget from '../../widget/widget'

import articleGridWidget from '../../widgets/general/article-grid'
import bannerWidget from '../../widgets/general/banner'
import bannerImageWidget from '../../widgets/general/banner-image'
import ctaWidget from '../../widgets/general/cta'
import faqWidget from '../../widgets/general/faq'
import formBuilderWidget from '../../widgets/general/form-builder'
import headerWidget from '../../widgets/general/header'
import heroWidget from '../../widgets/general/hero'
import htmlWidget from '../../widgets/general/html'
import imageWithTextWidget from '../../widgets/general/image-with-text'
import assetGridWidget from '../../widgets/general/asset-grid'
import inlineImageWidget from '../../widgets/general/inline-image'
import productGridWidget from '../../widgets/general/product-grid'
import contactFormWidget from '../../widgets/general/contact-form'
import promoWidget from '../../widgets/general/promo'
import productPromoWidget from '../../widgets/general/product-promo'
import promoDoubleWidget from '../../widgets/general/promo-double'
import promoDoubleSquaresWidget from '../../widgets/general/promo-double-squares'
import recommendationWizardWidget from '../../widgets/general/recommendation-wizard'
import superHeroWidget from '../../widgets/general/superhero'
import textWidget from '../../widgets/general/text'
import textHeroWidget from '../../widgets/general/text-hero'
import videoWidget from '../../widgets/general/video'
import videoSwitcherWidget from '../../widgets/general/video-switcher'
import gettingStartedContentWidget from '../../widgets/general/getting-started-content'
import typingGameWidget from '../../widgets/general/typing-game'
const createSectionLayoutWidgets = () => [
  widget(),

  articleGridWidget(),
  bannerWidget(),
  bannerImageWidget(),
  ctaWidget(),
  faqWidget(),
  formBuilderWidget(),
  headerWidget(),
  heroWidget(),
  htmlWidget(),
  imageWithTextWidget(),
  assetGridWidget(),
  inlineImageWidget(),
  productGridWidget(),
  contactFormWidget(),
  promoWidget(),
  promoDoubleWidget(),
  promoDoubleSquaresWidget(),
  productPromoWidget(),
  recommendationWizardWidget(),
  superHeroWidget(),
  textWidget(),
  textHeroWidget(),
  videoWidget(),
  videoSwitcherWidget(),
  gettingStartedContentWidget(),
  typingGameWidget()
]

export default createSectionLayoutWidgets
